@use '/src/styles/partials/_colors.scss' as colors;
@use '/src/styles/partials/_helpers.scss' as helpers;
@use '/src/styles/partials/_responsive.scss' as *;
@use '/src/styles/partials/_typography.scss' as typo;
@use '/src/styles/partials/_shadow.scss' as *;
@use 'sass:color';

$headerSize: 80px;
$heroPadding: 2.5rem;

.container {
  position: relative;
  @include helpers.flexCenter();
}

.wrapper {
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding-bottom: 10rem;
  gap: 10vh;
}

.hero {
  width: 100%;
  min-height: 100vh;
  position: relative;

  margin-top: -$headerSize;
  padding-top: calc($headerSize + $heroPadding);
  padding-bottom: $heroPadding;
  @include helpers.flexCenter();

  .container {
    gap: 7.5vh;
    height: 100%;
  }

  h1 {
    transition: all 0.2s ease-in;
    @include media('<sm') {
      @include typo.typeface-logo(mobile);
      margin-bottom: -1.2rem;
    }

    @include typo.typeface-logo(desktop);
    width: min-content;
    text-align: center;
    color: colors.$white-normal;
    text-shadow: 0px 26.4535px 21.1628px rgba(0, 0, 0, 0.219373),
      0px 16.0571px 12.8457px rgba(0, 0, 0, 0.188982),
      0px 9.67509px 7.74008px rgba(0, 0, 0, 0.161018),
      0px 5.38772px 4.31018px rgba(0, 0, 0, 0.130627),
      0px 2.31722px 1.85378px rgba(0, 0, 0, 0.0904136);
    margin-bottom: -2.2rem;
  }

  h3 {
    text-align: center;
    transition: all 0.2s ease-in;
    text-shadow: 0 2px 4px colors.$black-normal;

    @include media('<md') {
      @include typo.typeface(h4, mobile);
    }

    @include media('<sm') {
      @include typo.typeface(h5, mobile);
    }
  }
}

.heroBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  pointer-events: none;
  filter: blur(2px);

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background: linear-gradient(
      180deg,
      color.adjust(colors.$background, $alpha: -1) 65%,
      colors.$background 100%
    );
    z-index: 1;
  }

  img {
    object-fit: cover;
  }
}

$bpXl: map-get($breakpoints, 'xl');
$halfWidth: calc(min($bpXl, 100vw) / 2);
$quarterWidth: calc($halfWidth / 2);

.bestOfTheMonth {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h3 {
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
    }

    p {
      color: colors.$gray-4;
      text-align: center;
    }
  }

  .bestGamesGrid {
    width: 100%;
    margin-top: 2.5rem;
    display: grid;
    --gap: 2rem;
    gap: var(--gap);
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'first first second second'
      'first first third fourth';
    min-height: calc($halfWidth - var(--gap));

    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0.75rem;
      border-radius: 0.325rem;
      overflow: hidden;
      gap: 0.5rem;
      width: 100%;
      transition: all 0.1s;
      box-shadow: $shadow-gamecard;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          @if $i == 1 {
            height: calc($halfWidth - var(--gap));
            grid-area: first;

            h3 {
              @include typo.typeface(h4, desktop);
            }
          } @else if $i == 2 {
            height: calc($quarterWidth - var(--gap));
            grid-area: second;

            h3 {
              @include typo.typeface(h5, desktop);
            }
          } @else if $i == 3 {
            height: calc($quarterWidth - var(--gap));
            grid-area: third;

            h3 {
              @include typo.typeface(h6, desktop);
            }
          } @else if $i == 4 {
            height: calc($quarterWidth - var(--gap));
            grid-area: fourth;

            h3 {
              @include typo.typeface(h6, desktop);
            }
          }
        }
      }

      small {
        color: colors.$gray-6;
        font-weight: 400;
      }

      &:hover {
        transform: scale(1.05);
        filter: brightness(1.4);
        box-shadow: $shadow-btn-primary;
      }
    }

    .gridBg {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      top: 0;
      left: 0;
      pointer-events: none;

      &:before {
        content: '';
        background: linear-gradient(
          184.13deg,
          color.adjust(colors.$black-normal, $alpha: -0.7) 60%,
          colors.$black-dark 100%
        );
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        z-index: 1;
      }

      > span {
        position: relative;
      }

      img {
        object-fit: cover;
      }
    }

    @include media('>=md', '<=md') {
      --gap: 1rem;
      min-height: auto;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        'first first'
        'second second'
        'third fourth';

      > div {
        width: 100% !important;
        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            @if $i == 1 {
              height: 22rem;
            } @else if $i == 2 {
              height: 15rem;
            } @else {
              height: 12rem;
            }
          }
        }
      }
    }
    @include media('<sm') {
      grid-template-columns: 1fr;
      grid-template-areas:
        'first'
        'second'
        'third'
        'fourth';

      > div {
        width: 100% !important;
        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            @if $i == 1 {
              height: 17rem;
            } @else if $i == 2 {
              height: 12rem;
            } @else {
              height: 10rem;
            }
          }
        }
      }
    }
  }
}

@mixin ellipse() {
  background: colors.$purple-dark;
  position: absolute;
  border-radius: 100%;
  z-index: -1;
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);

  @content;
}

.publishYourOwn {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;

  gap: 7rem;
  text-align: center;
  align-items: center;

  p {
    text-align: center;
    color: colors.$gray-6;

    strong {
      color: colors.$white-dark;
    }

    + p {
      margin-top: 1rem;
    }
  }

  .ellipse-1 {
    @include ellipse() {
      right: 4vw;
      margin-top: -30rem;
      width: 25vw;
      height: 20vw;
      opacity: 0.6;
      filter: blur(max(7rem, 20vh));

      @include media('>=xl') {
        filter: blur(15rem);
      }

      @include media('<md') {
        filter: blur(10rem);
      }

      @include media('<sm') {
        filter: blur(7rem);
      }
    }
  }

  .ellipse-2 {
    @include ellipse() {
      left: 2vw;
      width: 30vw;
      height: 30vw;
      opacity: 0.8;
      filter: blur(15rem);

      @include media('>=xl') {
        filter: blur(25rem);
        width: 30vw;
        height: 30vw;
      }

      @include media('<md') {
        filter: blur(10rem);
      }

      @include media('<sm') {
        filter: blur(6rem);
      }
    }
  }

  .ellipse-3 {
    @include ellipse() {
      right: 0;
      width: 40vw;
      margin-bottom: -50vh;
      height: 40vw;
      opacity: 0.6;
      filter: blur(15rem);

      @include media('>=xl') {
        filter: blur(25rem);
      }

      @include media('<md') {
        filter: blur(12rem);
      }

      @include media('<sm') {
        filter: blur(6rem);
      }
    }
  }
}
